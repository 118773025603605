import React from "react";
import { useTranslation } from "react-i18next";
import { selectAppState } from "../../../app/slice/appSlice";
import { useAppSelector } from "../../../app/hooks";
import { Divider, Flex, ListItem, UnorderedList, useMediaQuery } from "@chakra-ui/react";
import { ToolTip, Typography, useCurrencyFormatter } from "@reconvert/react-ui-component";
import { Widget } from "../../../models";
import { getDiscountText, removeHtmlElementsFromString } from "../../../utils/helpers";
import { getProductToDisplayVerbose } from "../../../utils/getConditionText";
import SpecificProductBlock from "./SpecificProductBlock";

const WidgetView = ({ widgets, variantStatus }: { widgets: Widget[]; variantStatus: boolean }) => {
  const [isLargerThan1284] = useMediaQuery("(min-width: 1284px)");

  const {
    view: { isWidgetView },
  } = useAppSelector(selectAppState);

  if (!isWidgetView) return null;

  return (
    <>
      <Divider />
      {widgets.length === 0 ? (
        <NoWidgetsMessage variantStatus={variantStatus} />
      ) : (
        <WidgetList widgets={widgets} isLargerThan1284={isLargerThan1284} variantStatus={variantStatus} />
      )}
    </>
  );
};

const NoWidgetsMessage = ({ variantStatus }: { variantStatus: boolean }) => {
  const { t } = useTranslation();

  return (
    <Typography
      my={3}
      textAlign="center"
      variant="subheading2"
      color={variantStatus ? "neutrals.600" : "disable.color"}
    >
      {t("No widgets will be shown on this slot")}
    </Typography>
  );
};

const WidgetList = ({
  widgets,
  isLargerThan1284,
  variantStatus,
}: {
  widgets: Widget[];
  isLargerThan1284: boolean;
  variantStatus: boolean;
}) => {
  const { t } = useTranslation();
  const filteredWidgets = widgets?.filter(({ parentWidgetId, type }) => !parentWidgetId && type !== "DIVIDER");

  return (
    <>
      <Typography as="i" color={variantStatus ? "#6A7381" : "disable.color"} variant="tiny">
        {t("Hover the widget to see it’s details")}
      </Typography>
      <UnorderedList
        styleType="none"
        sx={{
          display: "grid",
          gridTemplateColumns: isLargerThan1284 ? "1fr 1fr" : "1fr",
          gap: "4px",
          marginLeft: "6px",
        }}
      >
        {filteredWidgets.map((widget, index) => (
          <ToolTip key={index} height="100%" placement="top" label={<WidgetDetail widgetInfo={widget} />}>
            <ListItem fontSize="14px" width="fit-content">
              <Typography variant="body"> &#x2022; {widget?.name || widget?.type}</Typography>
            </ListItem>
          </ToolTip>
        ))}
      </UnorderedList>
    </>
  );
};

const WidgetDetail = ({ widgetInfo }: { widgetInfo: Widget }) => {
  const widgetTypeComponents: Record<string, JSX.Element | null> = {
    TEXT: <WidgetInformation title="Text" text={widgetInfo?.text} />,
    BANNER: <WidgetInformation title="Banner" text={widgetInfo?.content?.title} />,
    TIMER: <TimerWidget widgetInfo={widgetInfo} />,
    MULTI_PRODUCT_UPSELL: <CommonWidget widgetInfo={widgetInfo} />,
    RECOMMENDATIONS: <CommonWidget widgetInfo={widgetInfo} />,
    BUNDLE: <CommonWidget widgetInfo={widgetInfo} />,
    UPSELL: <UpsellBlock widget={widgetInfo} />,
  };

  return widgetTypeComponents[widgetInfo.type] || null;
};

const CommonWidget = ({ widgetInfo }: { widgetInfo: Widget }) => (
  <Flex flexDirection="column">
    <Typography variant="body">{widgetInfo?.name}</Typography>
    {widgetInfo?.widgets?.map((widget, index) => <WidgetTypeRenderer widget={widget} />)}
  </Flex>
);

const TimerWidget = ({ widgetInfo }: { widgetInfo: Widget }) => {
  const { t } = useTranslation();
  const { days, hours, minutes, seconds } = widgetInfo?.upsellInfo?.settings?.duration;
  const timerDurationStr = `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;

  return (
    <>
      <Flex flexDirection="column" gap="2px">
        <Typography variant="small">{t("Timer")}</Typography>
        {timerDurationStr && <Typography variant="xsmall">- Duration: {timerDurationStr}</Typography>}
      </Flex>
    </>
  );
};

const WidgetTypeRenderer = ({ widget }: { widget: Widget }) => {
  const widgetComponents: Record<string, JSX.Element | null> = {
    TEXT: <WidgetInformation title="Text" text={widget?.text} />,
    BANNER: <WidgetInformation title="Banner" text={widget?.content?.title} />,
    UPSELL: <UpsellBlock widget={widget} />,
  };

  return widgetComponents[widget.type] || null;
};

const UpsellBlock = ({ widget }: { widget: Widget }) => (
  <UnorderedList pl="5px" width="100%">
    <ListItem>
      <WidgetInformation title="Product" />
      {widget?.upsellInfo?.type && (
        <Typography variant="xsmall">
          {widget?.upsellInfo?.type === "SPECIFIC_PRODUCT" ? (
            <SpecificProductBlock specificProduct={widget?.upsellInfo?.settings?.SPECIFIC_PRODUCT} isListItemView />
          ) : (
            <>- {getProductToDisplayVerbose(widget?.upsellInfo?.type)}</>
          )}
        </Typography>
      )}
      {widget?.blocks?.some(block => block?.type === "PRICE_AND_DISCOUNT") && <PriceAndDiscountBlock widget={widget} />}
    </ListItem>
  </UnorderedList>
);

const PriceAndDiscountBlock = ({ widget }: { widget: Widget }) => {
  const { storeInfo } = useAppSelector(selectAppState);
  const discount = widget?.blocks?.find(block => block?.type === "PRICE_AND_DISCOUNT")?.discount;
  const { currencyFormatter } = useCurrencyFormatter();

  return (
    <Typography variant="xsmall">
      - {getDiscountText(discount?.type === 'PERCENTAGE' ? discount?.discountValue : currencyFormatter(discount?.discountValue, storeInfo?.currency!), discount?.type)}
    </Typography>
  );
};

const WidgetInformation = ({ title, text }: { title: string; text?: string }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="2px">
      <Typography variant="small">{t(title)}</Typography>
      {text && <Typography variant="xsmall">- {removeHtmlElementsFromString(text)}</Typography>}
    </Flex>
  );
};

export default WidgetView;
