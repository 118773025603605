import { PageType } from "@reconvert/reconvert-utils";

export const Platform = {
  Shopify: "shopify",
  Wix: "wix",
} as const;

export type PlatformType = (typeof Platform)[keyof typeof Platform];

export type AllowedPlatform = Array<PlatformType>;

export interface ConditionsResponse {
  success: boolean;
  data: Condition[];
}

export interface SlotConfig {
  conditionId: string;
  emptySlotOption: EmptySlotOption;
  id: string;
  location: string;
  slotId: string;
  slotNo: Number;
}

export interface Condition {
  priority: number;
  rule: any;
  name: string;
  status: boolean;
  isDefault: boolean;
  scid: number;
  storeName: string;
  id: string;
  platformStoreId?: string;
  location?: string;
  variants?: Variant[];
  slotConfig?: SlotConfig[];
}

export interface DefaultCondition {
  name: string;
  isDefault: number;
  location: PageType;
}

export interface AddCondition {
  priority?: number;
  name: string;
  platformStoreId: string;
  location: string;
  rule: any;
  isDefault?: number;
}

export interface ConditionInfo extends Condition {
  slots?: Slot[];
  variants?: Variant[];
  slotConfig?:SlotConfig[];
}

export type EmptySlotOption = "CONTINUE" | "STOP";

export interface Slot {
  slotNo: number;
  places: string[];
  variants: Variant[];
  name: string;
  _id: string;
  id: string;
  status: boolean;
  emptySlotOption: EmptySlotOption;
  isABTestingEnabled: boolean;
}

export interface Variant {
  name: string;
  coverage: number;
  slotId: string;
  widgets: Widget[];
  id: string;
  status: boolean;
  abImpressionShare: number;
}

export interface Widget {
  upsellInfo: any;
  widgets: Widget[];
  category: string;
  status: boolean;
  name: string;
  text: string;
  type: string;
  isVisible: boolean;
  slotId: string;
  variantId: string;
  blocks: any[];
  parentWidgetId: string;
  id: string;
  content: any;
}

export interface ProductData {
  id: string;
  images: string[];
  title: string;
  subTitle?: string;
  variants: ProductVariant[];
}

export interface ProductVariant {
  id: string;
  title: string;
  price: string;
}

export interface ABTestVariant {
  name: string
  slotNo: number
  coverage: number
  status: boolean
  abImpressionShare: number
  id: string
  slotId: string
  slotName: string
  conditionId: string
  conditionName: string
  location: string
}

export interface WidgetAnalytics {
  content: any;
  name: string;
  text?: string;
  type: string;
  category: string;
  isVisible: boolean;
  slotId: string;
  variantId: string;
  parentWidgetId?: string;
  blocks: any[];
  widgets: WidgetAnalytics[];
  createdAt?: string;
  updatedAt?: string;
  id: string;
  upsellInfo?: {
    type: string;
    settings: any;
  };
  impressions: string;
  clicks: string;
  conversions: string;
  revenue: string;
  cvr: string;
  ctr: string;
  declined: string;
  declinedDiffPercentage: string;
  impressionsDiffPercentage: string;
  clicksDiffPercentage: string;
  conversionsDiffPercentage: string;
  revenueDiffPercentage: string;
  cvrDiffPercentage: string;
  ctrDiffPercentage: string;
}

export interface WidgetAnalytics {
  content: any;
  name: string;
  text?: string;
  type: string;
  category: string;
  isVisible: boolean;
  slotId: string;
  variantId: string;
  parentWidgetId?: string;
  blocks: any[];
  widgets: WidgetAnalytics[];
  createdAt?: string;
  updatedAt?: string;
  id: string;
  upsellInfo?: {
    type: string;
    settings: any;
  };
  impressions: string;
  clicks: string;
  conversions: string;
  revenue: string;
  cvr: string;
  ctr: string;
  declined: string;
  declinedDiffPercentage: string;
  impressionsDiffPercentage: string;
  clicksDiffPercentage: string;
  conversionsDiffPercentage: string;
  revenueDiffPercentage: string;
  cvrDiffPercentage: string;
  ctrDiffPercentage: string;
}