import React from "react";
import { Box, Divider, ListItem, UnorderedList } from "@chakra-ui/react";
import { Typography, useCurrencyFormatter } from "@reconvert/react-ui-component";
import AnalyticsRow from "../../VariantCard/component/AnalyticsRow";
import { getProductToDisplayVerbose } from "../../../utils/getConditionText";
import { selectAppState } from "../../../app/slice/appSlice";
import { useAppSelector } from "../../../app/hooks";
import { getDiscountText, removeHtmlElementsFromString } from "../../../utils/helpers";
import SpecificProductBlock from "../../VariantCard/component/SpecificProductBlock";
import { WidgetAnalytics } from "../../../models";

interface VariantAnalyticsProps {
  variantAnalyticData: WidgetAnalytics[];
  isABTestVariant?: boolean;
}

interface WidgetAnalyticsSectionProps {
  widget: WidgetAnalytics[];
  isABTestVariant?: boolean;
}

interface AnalyticsProps {
  widget: WidgetAnalytics;
}

const VariantAnalytics: React.FC<VariantAnalyticsProps> = ({ variantAnalyticData, isABTestVariant }) => {
  const {
    view: { isAnalyticView },
  } = useAppSelector(selectAppState);

  return (
    <Box height={"auto"}>
      {variantAnalyticData?.map((item: WidgetAnalytics, index: number) => (
        <React.Fragment key={index}>
          {item?.widgets.length > 0 && (
            <Typography
              mt={4}
              variant="small"
              fontWeight={500}
              color="neutrals.700"
              textTransform="uppercase"
              lineHeight={"20px"}
            >
              {item?.name}
            </Typography>
          )}

          <WidgetAnalyticsSection widget={item?.category === "CONTAINER" ? item?.widgets : [item]} isABTestVariant={isABTestVariant}/>
          {!isABTestVariant && <>
            {variantAnalyticData?.length !== index + 1 && <Divider margin="auto" mt={isAnalyticView ? 2 : 0} />}</>}
        </React.Fragment>
      ))}
    </Box>
  );
};

const WidgetAnalyticsSection: React.FC<WidgetAnalyticsSectionProps> = ({ widget, isABTestVariant }) => {
  const {
    view: { isAnalyticView },
  } = useAppSelector(selectAppState);

  return (
    <>
      {widget?.map((item: WidgetAnalytics, index: number) => (
        <Box key={index} my={"8px"}>
          <Typography variant="small" color="neutrals.700" fontWeight={500} lineHeight={"20px"}>
            {item.name}
          </Typography>
          {!isABTestVariant && <WidgetDetail widgetInfo={item} />}
          {isAnalyticView && <Analytics widget={item} />}
          {!isABTestVariant && <>
            {widget?.length !== index + 1 && <Divider width="251px" margin="auto" mt={isAnalyticView ? 2 : 0} />}</>}
        </Box>
      ))}
    </>
  );
};

const Analytics: React.FC<AnalyticsProps> = ({ widget }) => {
  switch (widget?.category) {
    case "INFORMATIONAL":
      return (
        <AnalyticsRow
          isForSidebar
          name="Impressions"
          value={widget?.impressions}
          change={widget?.impressionsDiffPercentage}
        />
      );
    case "CONVERTING":
      return (
        <>
          <AnalyticsRow
            isForSidebar
            name="Impressions"
            value={widget?.impressions}
            change={widget?.impressionsDiffPercentage}
          />
          <AnalyticsRow isForSidebar name="Click" value={widget?.clicks} change={widget?.clicksDiffPercentage} />
          <AnalyticsRow
            isForSidebar
            name="Conversions"
            value={widget?.conversions}
            change={widget?.conversionsDiffPercentage}
          />
          <AnalyticsRow isForSidebar name="CVR" value={widget?.cvr} change={widget?.cvrDiffPercentage} />
          <AnalyticsRow isForSidebar name="Revenue" value={widget?.revenue} change={widget?.revenueDiffPercentage} />
        </>
      );
    case "CLICKABLE":
      return (
        <>
          <AnalyticsRow
            isForSidebar
            name="Impressions"
            value={widget?.impressions}
            change={widget?.impressionsDiffPercentage}
          />
          <AnalyticsRow isForSidebar name="Click" value={widget?.clicks} change={widget?.clicksDiffPercentage} />
          <AnalyticsRow isForSidebar name="CTR" value={widget?.ctr} change={widget?.ctrDiffPercentage} />
        </>
      );
    default:
      return null;
  }
};

const WidgetDetail = ({ widgetInfo }: { widgetInfo: WidgetAnalytics }) => {
  const { storeInfo } = useAppSelector(selectAppState);
  const { currencyFormatter } = useCurrencyFormatter();

  if (widgetInfo?.type === "TEXT") {
    return (
      <Typography
        py="8px"
        color={"neutrals.600"}
        variant="xsmall"
        whiteSpace={"nowrap"}
        textOverflow={"ellipsis"}
        overflow={"hidden"}
      >
        {removeHtmlElementsFromString(widgetInfo?.text!)}
      </Typography>
    );
  }

  if (widgetInfo?.type === "BANNER") {
    return (
      <Typography
        py="8px"
        color={"neutrals.600"}
        variant="xsmall"
        whiteSpace={"nowrap"}
        textOverflow={"ellipsis"}
        overflow={"hidden"}
      >
        {removeHtmlElementsFromString(widgetInfo?.content?.title)}
      </Typography>
    );
  }

  if (widgetInfo?.type === "UPSELL") {
    const discount = widgetInfo?.blocks?.find(block => block?.type === "PRICE_AND_DISCOUNT")?.discount;

    return (
      <>
        <Box mx="5px" py={"8px"}>
          <UnorderedList stylePosition={"unset"} color={"neutrals.600"}>
            {widgetInfo?.upsellInfo?.type && (
              <ListItem>
                <Typography color={"neutrals.600"} variant="xsmall">
                  {widgetInfo?.upsellInfo?.type === "SPECIFIC_PRODUCT" ? (
                    <SpecificProductBlock specificProduct={widgetInfo?.upsellInfo?.settings?.SPECIFIC_PRODUCT} />
                  ) : (
                    getProductToDisplayVerbose(widgetInfo?.upsellInfo?.type)
                  )}
                </Typography>
              </ListItem>
            )}
            {discount?.enabled && (
              <ListItem>
                <Typography color={"neutrals.600"} variant="xsmall">
                  {getDiscountText(
                    discount?.type === 'PERCENTAGE' ? discount?.discountValue : currencyFormatter(discount?.discountValue, storeInfo?.currency!),
                    discount?.type,
                  )}
                </Typography>
              </ListItem>
            )}
          </UnorderedList>
        </Box>
      </>
    );
  }

  return <></>;
};

export default VariantAnalytics;
