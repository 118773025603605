import React from "react";

const DuplicateSlotIcon = () => {
  return (
    <svg width="70" height="73" viewBox="0 0 70 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <rect x="1" y="0.5" width="54" height="62" rx="3.5" fill="#F7F9FC" />
        <rect x="1" y="0.5" width="54" height="62" rx="3.5" stroke="#9EA7B6" />
        <rect x="5" y="4" width="46" height="33" rx="4.5" fill="white" stroke="#CBD5E0" />
        <rect x="5.00195" y="50" width="45" height="9" rx="4.5" fill="white" stroke="#CBD5E0" />
        <rect x="4.5" y="39.5" width="45" height="3" rx="1.5" fill="#DADEE6" />
        <rect x="4.5" y="44.5" width="34.7091" height="3" rx="1.5" fill="#DADEE6" />
      </g>
      <rect x="15" y="10.5" width="54" height="62" rx="3.5" fill="#F7F9FC" />
      <rect x="15" y="10.5" width="54" height="62" rx="3.5" stroke="#9EA7B6" />
      <rect x="19" y="14" width="46" height="33" rx="4.5" fill="#FCFDFF" stroke="#9EA7B6" />
      <rect x="19.002" y="60" width="45" height="9" rx="4.5" fill="#FCFDFF" stroke="#9EA7B6" />
      <rect x="18.5" y="49.5" width="45" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="18.5" y="54.5" width="34.7091" height="3" rx="1.5" fill="#DADEE6" />
    </svg>
  );
};

export default DuplicateSlotIcon;
