import { useCallback, useState } from "react";
import { dateDifferenceInDays, getCalculatedData, subtractDaysAndFormat } from "../utils/helpers";
import { getVariantAnalytics, getWidgetAnalytics } from "../services/conditions.service";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../app/slice/appSlice";
import { selectConditionsState } from "../app/slice/conditionSlice";
import { useToast } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import { Widget, WidgetAnalytics } from "../models";
import useVariants from "./useVariants";
import { AnalyticsRecord } from "../app/slice/analyticsSlice";
import { useCurrencyFormatter } from "@reconvert/react-ui-component";

interface AnalyticReturnType {
  variantAnalyticData: WidgetAnalytics[];
  loading: boolean;
  fetchAnalytics: (variant: string, base: 'SLOT_VARIANT' | 'WIDGET') => void
  variantSummaryAnalyticData: AnalyticsRecord[];
}

const useVariantAnalytics = (): AnalyticReturnType => {
  const { t } = useTranslation();
  const {
    dateRange,
    activePage,
    storeInfo,
    view: { isAnalyticView },
  } = useAppSelector(selectAppState);
  const { error } = useToast();
  const { selectedConditionId, conditions } = useAppSelector(selectConditionsState);
  const { variants } = useVariants();
  const [variantAnalyticData, setVariantAnalyticData] = useState<WidgetAnalytics[]>([]);
  const [variantSummaryAnalyticData, setVariantSummaryAnalyticData] = useState<AnalyticsRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { currencyFormatter } = useCurrencyFormatter();

  const fetchAnalytics = useCallback((variantId: string, base: 'SLOT_VARIANT' | 'WIDGET') => {
    const variant = variants?.find(a => a.id === variantId);

    const selectedVariantWidgets = variant?.widgets;
    
    const uniqueSpecificVariantWidget = selectedVariantWidgets?.reduce(
      (acc: any[], item: { category: string; id: string; parentWidgetId: string; type: string }) => {
        if (item.category === "CONTAINER") {
          const widgets = selectedVariantWidgets.filter(
            (widget: AnalyticsRecord) => widget.parentWidgetId === item.id && widget.type !== "DIVIDER",
          );

          acc.push({ ...item, widgets });
        } else if (!item.parentWidgetId && item.type !== "DIVIDER") {
          acc.push(item);
        }

        return acc;
      },
      [],
    );

    const getVariantAnalyticsDetail = async () => {
      setLoading(true);
      try {
        const dateDifference = dateDifferenceInDays(new Date(dateRange?.startDate), new Date(dateRange?.endDate));
        let currentAnalytics, previousAnalytics

        if (base === 'SLOT_VARIANT') {
          [currentAnalytics, previousAnalytics] = await Promise.all([
            getVariantAnalytics(dateRange?.startDate, dateRange?.endDate, variantId, activePage ?? ''),
            getVariantAnalytics(
              subtractDaysAndFormat(dateDifference, "YYYY-MM-DD", dateRange?.startDate),
              dateRange?.startDate,
              variantId,
              activePage ?? '',
            ),
          ]);

          const calculatedData = getCalculatedData(currentAnalytics?.data, previousAnalytics?.data);

          setVariantSummaryAnalyticData(calculatedData);
          return

        } else {
          [currentAnalytics, previousAnalytics] = await Promise.all([
            getWidgetAnalytics(dateRange?.startDate, dateRange?.endDate, variantId, activePage ?? ''),
            getWidgetAnalytics(
              subtractDaysAndFormat(dateDifference, "YYYY-MM-DD", dateRange?.startDate),
              dateRange?.startDate,
              variantId,
              activePage ?? '',
            ),
          ]);
        }


        const calculatedData = getCalculatedData(currentAnalytics.data, previousAnalytics.data);

        const updatedWidgets = uniqueSpecificVariantWidget?.map((widget: Widget) => {
          if (widget?.category === "CONTAINER") {
            const updatedSubWidgets = widget?.widgets?.map((subWid: Widget) => {
              const analyticsData = calculatedData.find(analyticsItem => analyticsItem?.baseId === subWid?.id);

              if (analyticsData) {
                return {
                  ...subWid,
                  impressions: analyticsData["impressions"] + "" || 0,
                  clicks: analyticsData["clicks"] + "" || 0,
                  conversions: analyticsData["conversions"] + "" || 0,
                  revenue: `${currencyFormatter(+analyticsData["revenue"], storeInfo?.currency!)}` || 0,
                  cvr: `${analyticsData["cvr"]}%` || 0,
                  ctr: analyticsData["ctr"] + "" || 0,
                  declined: analyticsData["declined"] + "" || 0,
                  impressionsDiffPercentage: analyticsData["impressionsDiffPercentage"] || 0,
                  clicksDiffPercentage: analyticsData["clicksDiffPercentage"] || 0,
                  conversionsDiffPercentage: analyticsData["conversionsDiffPercentage"] || 0,
                  revenueDiffPercentage: analyticsData["revenueDiffPercentage"] || 0,
                  cvrDiffPercentage: analyticsData["cvrDiffPercentage"] || 0,
                  ctrDiffPercentage: analyticsData["ctrDiffPercentage"] || 0,
                  declinedDiffPercentage: analyticsData["declinedDiffPercentage"] || 0,
                };
              } else {
                return subWid;
              }
            });

            return { ...widget, widgets: updatedSubWidgets };
          } else {
            const analyticsData = calculatedData.find(analyticsItem => analyticsItem?.baseId === widget?.id);

            if (analyticsData) {
              return {
                ...widget,
                impressions: analyticsData["impressions"] + "" || 0,
                clicks: analyticsData["clicks"] + "" || 0,
                conversions: analyticsData["conversions"] + "" || 0,
                revenue: `${currencyFormatter(+analyticsData["revenue"], storeInfo?.currency!)}` || 0,
                cvr: `${analyticsData["cvr"]}%` || 0,
                ctr: analyticsData["ctr"] + "" || 0,
                declined: analyticsData["declined"] + "" || 0,
                impressionsDiffPercentage: analyticsData["impressionsDiffPercentage"] || 0,
                clicksDiffPercentage: analyticsData["clicksDiffPercentage"] || 0,
                conversionsDiffPercentage: analyticsData["conversionsDiffPercentage"] || 0,
                revenueDiffPercentage: analyticsData["revenueDiffPercentage"] || 0,
                cvrDiffPercentage: analyticsData["cvrDiffPercentage"] || 0,
                ctrDiffPercentage: analyticsData["ctrDiffPercentage"] || 0,
                declinedDiffPercentage: analyticsData["declinedDiffPercentage"] || 0,
              };
            } else {
              return widget;
            }
          }
        });

        if (updatedWidgets) {
          setVariantAnalyticData(updatedWidgets as unknown as WidgetAnalytics[]);
        } else {
          setVariantAnalyticData([]);
        }
      } catch (e) {
        error(t("Error while fetching analytics"));
      } finally {
        setLoading(false);
      }
    };

    if (variantId && isAnalyticView) {
      getVariantAnalyticsDetail();
    } else {
      setVariantAnalyticData(uniqueSpecificVariantWidget);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activePage,
    conditions,
    dateRange?.endDate,
    dateRange?.startDate,
    selectedConditionId,
    variants
  ]);

  return { variantAnalyticData, loading, variantSummaryAnalyticData, fetchAnalytics };
};

export default useVariantAnalytics;
