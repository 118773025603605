import { Button, Flex, Select, Spinner } from "@chakra-ui/react";
import { Modal, SlotTemplateButton, Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import BannerIcon from "./icons/BannerIcon";
import StartFromScratch from "./icons/StartFromScratch";
import BundleIcon from "./icons/BundleIcon";
import DuplicateSlotIcon from "./icons/DuplicateSlotIcon";
import ProductRecommendationSlotIcon from "./icons/ProductRecommendationSlot";
import ProductUpsellIcon from "./icons/ProductUpsellIcon";
import { useAppSelector } from "../../app/hooks";
import {
  MAX_CUSTOM_SLOT,
  PageType,
  PreMadeTemplateSlotMapping,
  PreMadeTemplateType,
  SlotLocations,
} from "@reconvert/reconvert-utils";
import useSlots from "../../hooks/useSlots";
import { selectSlotsState } from "../../app/slice/slotSlice";
import { LoggerContext } from "@reconvert/react-logger";
import { getVariantsList } from "../../services/conditions.service";
import { getPageLocationText } from "../../utils/getConditionText";
import styled from "@emotion/styled";

const StyledSelect = styled(Select)`
  border: 1px solid #6a7381;
  transition: border-color 0.3s;
  min-width: 375px;
  height: 36px;

  &:hover {
    border: 2px solid #6a7381;
  }
`;

export const getPreMadeTemplates = (isAddWidget: boolean) => [
  {
    icon: <StartFromScratch />,
    title: "Start from scratch",
    type: PreMadeTemplateType.CUSTOM_SLOT,
    category: "custom",
  },
  {
    icon: <DuplicateSlotIcon />,
    title: "Duplicate existing slot",
    type: PreMadeTemplateType.DUPLICATE_SLOT,
    category: "custom",
  },
  {
    icon: <ProductUpsellIcon />,
    title: "Product upsell",
    type: PreMadeTemplateType.PRODUCT_UPSELL,
    slotNo: PreMadeTemplateSlotMapping[PreMadeTemplateType.PRODUCT_UPSELL]?.slotNo,
    category: "premade",
  },
  {
    icon: <ProductRecommendationSlotIcon />,
    title: "Product recommendations",
    type: PreMadeTemplateType.PRODUCT_RECOMMENDATION,
    slotNo: PreMadeTemplateSlotMapping[PreMadeTemplateType.PRODUCT_RECOMMENDATION]?.slotNo,
    category: "premade",
  },
  {
    icon: <BundleIcon />,
    title: "Bundle",
    type: PreMadeTemplateType.BUNDLE,
    slotNo: PreMadeTemplateSlotMapping[PreMadeTemplateType.BUNDLE]?.slotNo,
    category: "premade",
  },
  {
    icon: <BannerIcon />,
    title: "Banner",
    type: PreMadeTemplateType.BANNER,
    allowPages: SlotLocations,
    slotNo: PreMadeTemplateSlotMapping[PreMadeTemplateType.BANNER]?.slotNo,
    category: "premade",
  },
];

const PreMadeTemplateModal = ({
  showPreMadeTemplateModal,
  slotNo,
  onClose,
  setShowPreMadeTemplateModal,
}: {
  showPreMadeTemplateModal: boolean;
  slotNo: number | null;
  onClose: () => void;
  setShowPreMadeTemplateModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const [selectedTemplateType, setSelectedTemplateType] = React.useState<PreMadeTemplateType | null>(null);
  const { slots } = useAppSelector(state => state.slots);
  const { activePage, platformStoreId } = useAppSelector(state => state.app);
  const { creatingSlot, isButtonDisabled } = useAppSelector(selectSlotsState);
  const { handleCreateSlot } = useSlots();
  const { logger } = useContext(LoggerContext);
  const [loading, setLoading] = React.useState(false);
  const [variants, setVariants] = React.useState([]);
  const [variantId, setVariantId] = React.useState("");
  const isAllCustomSlotsCreated =
    new Set<number>(slots?.filter(a => a.slotNo <= MAX_CUSTOM_SLOT).map(a => a.slotNo))?.size === MAX_CUSTOM_SLOT;

  const fetchVariants = useCallback(async () => {
    try {
      setLoading(true);

      const res = await getVariantsList(activePage!);

      if (res.length > 0) {
        setVariants(
          res.map((variant: any) => {
            return {
              label: `${getPageLocationText(variant.location as PageType)} | ${variant.conditionName} | ${variant.slotName}`,
              value: variant.id,
            };
          }),
        );
      }
    } catch (error) {
      console.error("Error while fetching variants", error);
    } finally {
      setLoading(false);
    }
  }, [activePage]);

  useEffect(() => {
    if (selectedTemplateType === PreMadeTemplateType?.DUPLICATE_SLOT && variants.length === 0) {
      fetchVariants();
    }
  }, [fetchVariants, selectedTemplateType]);

  /**
   * Filtering logic:
   * - If all custom slots have been created, remove templates of type `CUSTOM_SLOT` and `DUPLICATE_SLOT`.
   * - If a template's slot is already present in the `slots` array, remove that template.
   */
  const filteredPreMadeTemplates = useMemo(() => {
    // Filter templates based on whether they allow the active page
    const filteredTemplates = getPreMadeTemplates(!!slotNo).filter(
      a => !a.allowPages || a.allowPages.includes(activePage!),
    );

    if (slotNo) {
      return filteredTemplates;
    }

    // Filter templates based on whether all custom slots have been created and whether there are available slots for the template
    return filteredTemplates.filter(template => {
      if (
        isAllCustomSlotsCreated &&
        (template.type === PreMadeTemplateType.CUSTOM_SLOT || template.type === PreMadeTemplateType.DUPLICATE_SLOT)
      )
        return false;

      const templateSlotNo = PreMadeTemplateSlotMapping[template.type]?.slotNo;
      const isSlotAvailable = !slots.some(slot => slot.slotNo === templateSlotNo);

      return isSlotAvailable;
    });
  }, [slotNo, activePage, isAllCustomSlotsCreated, slots]);

  const hasMoreThanOneCustomTemplate = useMemo(
    () => filteredPreMadeTemplates.filter(t => t.category === "custom").length > 0,
    [filteredPreMadeTemplates],
  );

  const hasMoreThanOnePremadeTemplate = useMemo(
    () => filteredPreMadeTemplates.filter(t => t.category === "premade").length > 0,
    [filteredPreMadeTemplates],
  );

  const buttonDisabled = useMemo(() => {
    if (selectedTemplateType === PreMadeTemplateType?.DUPLICATE_SLOT) {
      return variants.length === 0 || !variantId || isButtonDisabled;
    } else {
      return !selectedTemplateType || isButtonDisabled;
    }
  }, [selectedTemplateType, variants.length, variantId, isButtonDisabled]);

  const createSlotWithTemplate = (templateType: PreMadeTemplateType) => {
    if (templateType === PreMadeTemplateType.DUPLICATE_SLOT) {
      logger.info(`Creating slot with template :: ${platformStoreId}`, { templateType });
      handleCreateSlot(slotNo, null, variantId);
      return;
    }

    if (templateType === PreMadeTemplateType.CUSTOM_SLOT) {
      logger.info(`Creating custom slot :: ${platformStoreId}`);
      handleCreateSlot && handleCreateSlot(slotNo, null, null);
      return;
    }

    const templateSlotNo = PreMadeTemplateSlotMapping[templateType]?.slotNo;

    logger.info(`Creating slot with template :: ${platformStoreId}`, { templateType, templateSlotNo });
    handleCreateSlot && handleCreateSlot(slotNo, templateSlotNo || null, null);
  };

  return (
    <>
      <Modal
        isOpen={showPreMadeTemplateModal}
        onClose={() => {
          onClose();
          setVariants([]);
          setSelectedTemplateType(null);
        }}
        closeOnEsc={true}
        title={!!slotNo ? t("Add widgets") : t("Create a slot")}
        size="4xl"
        hideFooter
      >
        {!hasMoreThanOneCustomTemplate && !hasMoreThanOnePremadeTemplate ? (
          <>
            <Flex height={"158px"} flex={1} justifyContent={"center"} alignItems={"center"}>
              <Spinner size={"lg"} />
            </Flex>
          </>
        ) : (
          <>
            {hasMoreThanOnePremadeTemplate && hasMoreThanOneCustomTemplate && (
              <Flex justifyContent={"center"} alignItems={"center"} mt={5}>
                <Typography variant={"body"} fontWeight={500}>
                  {!!slotNo ? t("Templates") : t("Pre-made slot")}
                </Typography>
              </Flex>
            )}
            <Box
              sx={{ justifyContent: "center" }}
              display={"flex"}
              mt={3}
              flexWrap={"wrap"}
              justifyContent={"center"}
              gap={"24px"}
              mx={"auto"}
            >
              {filteredPreMadeTemplates.map(
                a =>
                  a.category === "premade" && (
                    <SlotTemplateButton
                      icon={a.icon}
                      title={a.title}
                      isSelected={selectedTemplateType === a.type}
                      checkbox={false}
                      onClick={isSelected => {
                        if (isSelected) {
                          setSelectedTemplateType(a.type);
                        } else {
                          setSelectedTemplateType(null);
                        }
                      }}
                    />
                  ),
              )}
            </Box>
            {hasMoreThanOneCustomTemplate && hasMoreThanOnePremadeTemplate && (
              <Flex justifyContent={"center"} alignItems={"center"} mt={5}>
                <Typography variant={"body"} fontWeight={500}>
                  {!!slotNo ? t("Custom") : t("Custom slot")}
                </Typography>
              </Flex>
            )}
            <Box
              sx={{ justifyContent: "center" }}
              display={"flex"}
              mt={3}
              flexWrap={"wrap"}
              justifyContent={"center"}
              gap={"24px"}
              mx={"auto"}
            >
              {filteredPreMadeTemplates.map(
                a =>
                  a.category === "custom" && (
                    <SlotTemplateButton
                      icon={a.icon}
                      title={a.title}
                      isSelected={selectedTemplateType === a.type}
                      checkbox={false}
                      onClick={isSelected => {
                        if (isSelected) {
                          setSelectedTemplateType(a.type);
                        } else {
                          setSelectedTemplateType(null);
                        }
                      }}
                    />
                  ),
              )}
            </Box>

            <Flex justifyContent={"center"} alignItems={"center"} my="5">
              {selectedTemplateType === "DUPLICATE_SLOT" && (
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                  {loading ? (
                    <Box height={"36px"} flex={1}>
                      <Spinner size={"lg"} />
                    </Box>
                  ) : (
                    <Flex alignItems="center" justifyContent="center" gap={"10px"}>
                      <Typography variant="body">Select Slot</Typography>
                      <Box flex={1} width={"374px"}>
                        <StyledSelect
                          placeholder={t("Select slot to duplicate")}
                          onChange={e => setVariantId(e.target.value)}
                          value={variantId}
                        >
                          {variants.map((variant: any) => (
                            <option key={variant.value} value={variant.value}>
                              {variant.label}
                            </option>
                          ))}
                        </StyledSelect>
                      </Box>
                    </Flex>
                  )}
                </Box>
              )}
            </Flex>
          </>
        )}

        <Box display="flex" justifyContent={"center"} my={6}>
          <Button
            padding={"8px 16px"}
            borderRadius={"4px"}
            border={"1px solid"}
            disabled={true}
            borderColor={"neutrals.500"}
            variant={"solid"}
            isDisabled={buttonDisabled}
            onClick={() => createSlotWithTemplate(selectedTemplateType!)}
            isLoading={creatingSlot}
          >
            {!!slotNo ? t("Add") : t("Create")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PreMadeTemplateModal;
