import React from "react";

const ProductUpsellIcon = () => {
  return (
    <svg width="102" height="53" viewBox="0 0 102 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="4" width="44.4831" height="32" rx="4.5" fill="#FCFDFF" stroke="#9EA7B6" />
      <rect x="47.4829" y="6.5" width="38.5618" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="47.4829" y="11.5" width="29.3405" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="47.4829" y="20.5" width="38.5618" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="47.4829" y="25.5" width="38.5618" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="47.4829" y="30.5" width="38.5618" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="0.5" y="43" width="101" height="9" rx="4.5" fill="#FCFDFF" stroke="#9EA7B6" />
    </svg>
  );
};

export default ProductUpsellIcon;
