import React from "react";

const BannerIcon = () => {
  return (
    <svg width="112" height="61" viewBox="0 0 112 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="14.5003" width="111" height="32" rx="4.5" fill="#FCFDFF" />
      <rect x="0.5" y="14.5003" width="111" height="32" rx="4.5" stroke="#9EA7B6" />
      <rect x="69.438" y="19.5003" width="38.5618" height="2" rx="1" fill="#DADEE6" />
      <rect x="69.438" y="23.5003" width="29.3405" height="2" rx="1" fill="#DADEE6" />
      <rect x="69.438" y="31.5003" width="38.5618" height="2" rx="1" fill="#DADEE6" />
      <rect x="69.438" y="35.5003" width="38.5618" height="2" rx="1" fill="#DADEE6" />
      <rect x="69.438" y="39.5003" width="38.5618" height="2" rx="1" fill="#DADEE6" />
    </svg>
  );
};

export default BannerIcon;
