import { Box } from "@chakra-ui/react";
import { Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../app/hooks";
import { toggleHelpModal } from "../../../../app/slice/appSlice";
import { APP_PLATFORM } from "../../../../config";
import { Platform } from "../../../../models";

const ConditionTitle = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
      <Typography variant="subheading1" fontSize={"16px"}>
        {t("CONDITION")}
      </Typography>
      <Box my={2}>
        <Typography
          color="link.color"
          variant="body"
          as="a"
          cursor="pointer"
          lineHeight={"20px"}
          className={APP_PLATFORM === Platform.Wix ? "link" : " "}
          onClick={() =>
            dispatch(
              toggleHelpModal({
                link: "https://help.reconvert.io/en/article/conditions-settings-y9hbv/reader/",
                title: t("Conditions settings").toString(),
              }),
            )
          }
        >
          {t("Learn more about conditions")}
        </Typography>
      </Box>
    </>
  );
};

export default ConditionTitle;
