import { Box, Center, Flex, Switch } from "@chakra-ui/react";
import { BorderedCard, Icons, Typography } from "@reconvert/react-ui-component";
import useVariants from "../../hooks/useVariants";
import AnalyticView from "./component/AnalyticView";
import WidgetView from "./component/WidgetView";
import useUnsavedBar, { PreventActionType, PreventActionTypeObject } from "../../hooks/useUnsavedBar";
import { Platform, Slot } from "../../models";
import { PreMadeTemplateSlotMapping, PreMadeTemplateType } from "@reconvert/reconvert-utils";
import { APP_PLATFORM } from "../../config";
import { useAppDispatch } from "../../app/hooks";
import { setSelectedSlot } from "../../app/slice/conditionSlice";
import useConditions from "../../hooks/useConditions";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type PreMadeTemplatesIconMapping = Record<PreMadeTemplateType, React.ReactNode>;

const ICON_MAPPING: Partial<PreMadeTemplatesIconMapping> = {
  [PreMadeTemplateType.PRODUCT_UPSELL]: <Icons.UpSell />,
  [PreMadeTemplateType.PRODUCT_RECOMMENDATION]: <Icons.Star />,
  [PreMadeTemplateType.BUNDLE]: <Icons.Package />,
  [PreMadeTemplateType.BANNER]: <Icons.FlagBanner />,
};

const TEMPLATE_ICON_ARRAY = Object.values(PreMadeTemplateSlotMapping)
  .map(a => a.slotNo)
  .map(slotNo => {
    const templateType = Object.keys(PreMadeTemplateSlotMapping).find(
      key => PreMadeTemplateSlotMapping[key as keyof typeof PreMadeTemplateSlotMapping]?.slotNo === slotNo,
    );

    return {
      slotNo,
      icon: ICON_MAPPING[templateType as PreMadeTemplateType],
    };
  });

const Card = ({ variant, slot }: { slot: Slot; variant: any }) => {
  const { selectedVariant, handleSelectedVariantUpdate, handleVariantStatusUpdate } = useVariants();
  const { selectedCondition } = useConditions();
  const { preventAction } = useUnsavedBar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const totalVariants = useMemo(() => {
    return selectedCondition?.variants?.filter(a => a.slotId === slot?.id)?.length ?? 0;
  }, [selectedCondition?.variants, slot?.id]);

  return (
    <BorderedCard
      mx={"16px"}
      borderPosition="top"
      borderColor={selectedVariant?.slotId === variant?.slotId ? `secondary.600` : `neutrals.700`}
      borderRadius={"4px"}
    >
      <Box
        onClick={() => {
          preventAction(
            () => handleSelectedVariantUpdate(variant.id),
            PreventActionTypeObject.INLINE_CHANGE as PreventActionType,
          );
          preventAction(() => dispatch(setSelectedSlot(slot)));
        }}
        p={4}
        width={"100%"}
        background={`neutrals.white`}
        boxShadow={"0px 2px 8px 0px rgba(106, 115, 129, 0.12);"}
        _hover={{
          background: !variant.status ? "neutrals.200" : "grays.1",
        }}
        cursor={"pointer"}
        bg={!variant.status ? "#F7F9FC" : undefined}
        color={!variant.status ? "disable.color" : undefined}
      >
        <Flex gap={2} mb={3} alignItems={"center"}>
          {APP_PLATFORM === Platform.Shopify && TEMPLATE_ICON_ARRAY.find(a => a.slotNo === slot.slotNo)?.icon}
          {slot?.isABTestingEnabled ? (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#clip0_11986_59521)">
                  <path
                    d="M3.84656 0.351806C3.76145 0.139294 3.55825 0 3.3342 0C3.11015 0 2.90695 0.139294 2.82185 0.351806L0.737685 5.49496L0.0429653 7.20935C-0.0751372 7.50044 0.0585965 7.83439 0.341695 7.95582C0.624793 8.07726 0.949575 7.93975 1.06768 7.64866L1.61998 6.28608H5.04669L5.59899 7.64866C5.71709 7.93975 6.04187 8.07726 6.32497 7.95582C6.60807 7.83439 6.7418 7.50044 6.6237 7.20935L5.92898 5.49496L3.84482 0.351806H3.84656ZM4.5847 5.14316H2.08371L3.3342 2.05726L4.5847 5.14316Z"
                    fill="#363C45"
                  />
                  <path
                    d="M10.6663 7.3335C9.93092 7.3335 9.33301 7.8887 9.33301 8.57159V11.6668V14.7621C9.33301 15.445 9.93092 16.0002 10.6663 16.0002H13.333C14.8059 16.0002 15.9997 14.8917 15.9997 13.524C15.9997 12.6244 15.483 11.8351 14.708 11.4018C15.0976 10.9704 15.333 10.4152 15.333 9.80969C15.333 8.44198 14.1393 7.3335 12.6663 7.3335H10.6663ZM12.6663 11.0478H10.6663V8.57159H12.6663C13.4018 8.57159 13.9997 9.1268 13.9997 9.80969C13.9997 10.4926 13.4018 11.0478 12.6663 11.0478ZM10.6663 12.2859H12.6663H13.333C14.0684 12.2859 14.6663 12.8411 14.6663 13.524C14.6663 14.2069 14.0684 14.7621 13.333 14.7621H10.6663V12.2859Z"
                    fill="#363C45"
                  />
                  <path
                    d="M4.66699 13.3335L11.3337 2.66683"
                    stroke="#363C45"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_11986_59521">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography width={"100%"} fontWeight={500} variant="subheading1-r">
                {totalVariants} {t("Variants")}
              </Typography>
            </>
          ) : (
            <Typography width={"100%"} fontWeight={500} variant="subheading1-r">
              {variant.name}
            </Typography>
          )}
          <Center onClick={e => e.stopPropagation()}>
            <Typography variant="small" fontWeight={400} mr={2} color="neutrals.900">
              {variant.status ? "On" : "Off"}
            </Typography>
            <Switch
              size="sm"
              key={variant.status}
              defaultChecked={variant.status}
              checked={variant.status}
              onChange={e =>
                preventAction(
                  () =>
                    handleVariantStatusUpdate(
                      variant.id,
                      variant.status,
                      e.target.checked,
                      slot.id,
                    ),
                  PreventActionTypeObject.INLINE_CHANGE as PreventActionType,
                )
              }
            />
          </Center>
          <Box>{/* <Icons.Eye color={"#6A7381"} cursor="pointer" /> */}</Box>
        </Flex>
        <AnalyticView slotId={slot?.id} variantStatus={variant?.status} />
        {!slot?.isABTestingEnabled && <WidgetView widgets={variant?.widgets} variantStatus={variant?.status} />}
      </Box>
    </BorderedCard>
  );
};

export default Card;
