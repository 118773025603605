import { PageType } from "@reconvert/reconvert-utils";

export const getProductToDisplayVerbose = (value: String) => {
  switch (value) {
    case `SHOPIFY_RECOMMENDATIONS`:
      return `Shopify Recommendations`;

    case `MOST_EXPENSIVE`:
      return `Most expensive product in the cart`;

    case `LEAST_EXPENSIVE`:
      return `Cheapest product in the cart`;

    case `WISER_RECOMMENDATIONS`:
      return `Wiser recommendations`;

    case `PERSONALIZED_RECOMMENDATIONS`:
      return `Personalized recommendations`;

    case `REBUY_INTELLIGENT_CROSS_SELL`:
      return `Rebuy intelligent cross sell`;

    case `RECOMATIC_RECOMMENDATIONS`:
      return `Recomatic recommendations`;

    case `SAME_CATEGORIES`:
      return `From the same category`;

    case `BEST_SELLERS`:
      return `Best sellers`;

    default:
      break;
  }
};

export function getPageLocationText(value: PageType) {
  switch (value) {
    case PageType.CHECKOUT:
      return "Checkout";
    case PageType.POST_PURCHASE_PAGE1:
      return "Post purchase (Page 1)";
    case PageType.POST_PURCHASE_PAGE2:
      return "Post purchase (Page 2)";
    case PageType.THANK_YOU_PAGE:
      return "Thank you page";
    case PageType.ORDER_STATUS_PAGE:
      return "Order status page";
    case PageType.PRE_PURCHASE_CART_DRAWER_PAGE:
      return "Cart drawer";
    case PageType.PRE_PURCHASE_CART_PAGE:
      return "Cart page";
    case PageType.PRE_PURCHASE_PRODUCT_PAGE:
      return "Product page";
    default:
      return value;
  }
}
