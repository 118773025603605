import { ABTestVariant } from "../models";
import { GET_ALL_VARIANTS, UPDATE_VARIANT, UpdateOrDeleteResponseBody } from "../utils/constant";
import { getJson, putJson } from "../utils/network";

export async function getAllVariants(
  conditionId: string,
  slotNo: number,
  excludeWidgets: boolean,
): Promise<ABTestVariant[]> {
  const path = GET_ALL_VARIANTS(conditionId, slotNo, excludeWidgets);
  const { response } = await getJson<ABTestVariant[]>(path);

  return response as ABTestVariant[];
}

export async function updateVariant(data: { conditionId: string; slotId: string; status?: boolean }) {
  const path = UPDATE_VARIANT;
  const { response } = await putJson<UpdateOrDeleteResponseBody>(
    path,
    Object.fromEntries(Object.entries(data).filter(([key, value]) => value != null || value !== undefined)),
  );

  return response;
}
