import { useToast } from "@reconvert/react-ui-component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectAppState, setIFrameLink, updateBreadcrumbValue } from "../app/slice/appSlice";
import {
  createVariantAsync,
  deleteVariantAsync,
  selectConditionsState,
  setSelectedSlot,
  setSelectedVariantId,
  updateVariant,
  updateVariantAsync,
  updateVariantAsyncByData,
} from "../app/slice/conditionSlice";
import { APP_PLATFORM, WIDGET_EDITOR_URL } from "../config";
import { Platform, Slot } from "../models";
import useConditions from "./useConditions";
import { getToken } from "../services/auth.service";
import { generateBreadcrumbFormat, getNextVariantName, updateAbImpressionShares } from "../utils/helpers";
import useUnsavedBar from "./useUnsavedBar";
import { selectSlotsState } from "../app/slice/slotSlice";

interface VariantReturnType {
  selectedVariant: any;
  selectedSlot: Slot | undefined | null;
  handleSelectedVariantUpdate: (id: string) => void;
  handleVariantStatusUpdate: (id: string, oldStatus: any, status: any, slotId: string) => void;
  handleVariantNameUpdate: (id: string, oldName: any, name: any) => void;
  handleAddWidget: (slot: Slot, variantId?: string, variantName?: string) => void;
  creatingVariant: boolean;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (value: boolean) => void;
  deletingVariant: boolean;
  handleDeleteVariant: () => void;
  createABVariant: () => void;
  variants: any[] | undefined;
  navigateToEditor: (variantId: string) => void;
  handleUpdateVariantImpressionShare: (variantId: string, old: number, newValue: number) => void;
  handleDeleteVariantId: (variant: string) => void;
}

const useVariants = (): VariantReturnType => {
  const { t } = useTranslation();
  const { success, error } = useToast();
  const dispatch = useAppDispatch();
  const { pushUnsavedChanges, removeUnsavedChange } = useUnsavedBar();
  const { platformStoreId, activePage, isUnsavedBarEnabled } = useAppSelector(selectAppState);
  const { slots } = useAppSelector(selectSlotsState);
  const { selectedVariantId, selectedSlot } = useAppSelector(selectConditionsState);
  const { selectedCondition } = useConditions();
  const selectedVariant = useMemo(
    () => (selectedCondition?.variants || []).find((v: any) => v.id === selectedVariantId),
    [selectedCondition, selectedVariantId],
  );

  const [creatingVariant, setCreatingVariant] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingVariant, setDeletingVariant] = useState<boolean>(false);

  const variants = useMemo(() => {
    return selectedCondition?.variants?.filter(a => a.slotId === selectedSlot?.id);
  }, [selectedCondition?.variants, selectedSlot?.id]);

  const handleSelectedVariantUpdate = useCallback(
    (id: string) => {
      dispatch(setSelectedVariantId(id));
    },
    [dispatch],
  );

  useEffect(() => {
    selectedVariant?.name &&
      dispatch(
        updateBreadcrumbValue({
          breadCrumb: generateBreadcrumbFormat({
            page: activePage,
            condition: selectedCondition?.name,
            variant: selectedSlot?.isABTestingEnabled ? selectedSlot?.name : selectedVariant?.name,
          }),
        }),
      );
  }, [activePage, dispatch, selectedCondition?.name, selectedVariant?.name, selectedSlot, variants?.length, t]);

  useEffect(() => {
    const slot = slots.find(a => a.id === selectedVariant?.slotId);

    if (slot?.id && slot?.id !== selectedSlot?.id) {
      dispatch(setSelectedSlot(slot));
    }
  }, [dispatch, selectedVariant?.slotId, slots, selectedSlot?.id]);

  const handleVariantStatusUpdate = useCallback(
    (id: string, oldStatus: any, status: any, slotId: string) => {
      dispatch(updateVariant({ id, status }));

      pushUnsavedChanges({
        id: "variantStatusChange",
        type: "VARIANT",
        saveAction: () => {
          dispatch(
            updateVariantAsyncByData({
              body: { status, slotId, conditionId: selectedCondition?.id },
            }),
          )
            .unwrap()
            .then(() => {
              if (!isUnsavedBarEnabled) success(t("Variant status changed successfully."));
            })
            .catch(() => {
              error(t("Failed to change variant status."));
              dispatch(updateVariant({ id, status: oldStatus }));
            });
        },
        discardAction: () => dispatch(updateVariant({ id, status: oldStatus })),
      });
    },
    [dispatch, pushUnsavedChanges, selectedCondition?.id, isUnsavedBarEnabled, success, t, error],
  );

  const handleVariantNameUpdate = useCallback(
    (id: string, oldName: any, name: any) => {
      dispatch(updateVariant({ id, name }));
      dispatch(updateVariantAsync({ id, body: { name } }))
        .unwrap()
        .then(() => {
          if (!isUnsavedBarEnabled) success(t("Variant name changed successfully."));
        })
        .catch(() => {
          error(t("Failed to change variant name."));
          dispatch(updateVariant({ id, name: oldName }));
        });
    },
    [dispatch, isUnsavedBarEnabled, success, t, error],
  );

  const handleAddWidget = useCallback(
    async (slot: Slot, variantId?: string, variantName?: string) => {
      if (variantId) {
        dispatch(setSelectedVariantId(variantId));
        dispatch(setSelectedSlot(slot));
        dispatch(
          updateBreadcrumbValue({
            breadCrumb: generateBreadcrumbFormat({
              page: activePage,
              condition: selectedCondition?.name,
              variant: variantName,
            }),
          }),
        );

        const tokenRes = await getToken(platformStoreId!);

        if (tokenRes.response?.token) {
          dispatch(
            setIFrameLink(
              `${WIDGET_EDITOR_URL}?variant=${variantId}${
                activePage! ? `&type=${activePage!}` : ""
              }&platformStoreId=${platformStoreId}&token=${tokenRes.response.token}&conditionId=${selectedCondition?.id}&slotId=${slot?.id}&slotNo=${slot?.slotNo}${APP_PLATFORM === Platform.Wix ? "&isNewVariant=true" : ""}`,
            ),
          );
        }
      } else {
        setCreatingVariant(true);
        const body = {
          name: `${selectedCondition?.isDefault ? "Default" : selectedCondition?.name} - ${slot.name.toLocaleLowerCase()}`,
          coverage: 100,
          slotId: slot.id,
          conditionId: selectedCondition?.id,
        };

        dispatch(createVariantAsync(body))
          .unwrap()
          .then(async (res: any) => {
            dispatch(setSelectedVariantId(res?.data?.id));
            dispatch(setSelectedSlot(slot));
            dispatch(
              updateBreadcrumbValue({
                breadCrumb: generateBreadcrumbFormat({
                  page: activePage,
                  condition: selectedCondition?.name,
                  variant: res?.data?.name,
                }),
              }),
            );
            const tokenRes = await getToken(platformStoreId!);

            if (tokenRes.response?.token) {
              dispatch(
                setIFrameLink(
                  `${WIDGET_EDITOR_URL}?variant=${res.data[0].id}${
                    activePage! ? `&type=${activePage!}` : ""
                  }&platformStoreId=${platformStoreId}&token=${tokenRes.response.token}${APP_PLATFORM === Platform.Wix ? "&isNewVariant=true" : ""}&conditionId=${selectedCondition?.id}&slotId=${slot?.id}&slotNo=${slot?.slotNo}`,
                ),
              );
            }
          })
          .catch(() => error(t("Failed to add variant.")))
          .finally(() => setCreatingVariant(false));
      }
    },
    [dispatch, activePage, platformStoreId, selectedCondition, error, t],
  );

  const handleDeleteVariant = useCallback(() => {
    if (!selectedVariantId) return;

    setDeletingVariant(true);
    dispatch(deleteVariantAsync(selectedVariantId))
      .unwrap()
      .then(() => {
        dispatch(
          updateBreadcrumbValue({
            breadCrumb: generateBreadcrumbFormat({ page: activePage, condition: selectedCondition?.name }),
          }),
        );
        success(t("Variant deleted successfully."));
      })
      .catch(() => error(t("Error while deleting variant.")))
      .finally(() => {
        setIsDeleteModalOpen(false);
        setDeletingVariant(false);
      });
  }, [selectedVariantId, dispatch, activePage, selectedCondition?.name, success, t, error]);

  const createABVariant = useCallback(() => {
    setCreatingVariant(true);
    const body = {
      name:
        (variants?.length ?? 0) === 1 ? "Variant B" : getNextVariantName(variants?.[variants.length - 1]?.name ?? ""),
      coverage: 50,
      slotId: selectedSlot?.id,
      conditionId: selectedCondition?.id,
      isABTesting: true,
    };

    dispatch(createVariantAsync(body))
      .unwrap()
      .then(async (res: any) => {
        dispatch(setSelectedVariantId(res?.data?.id));
        dispatch(
          updateBreadcrumbValue({
            breadCrumb: generateBreadcrumbFormat({
              page: activePage,
              condition: selectedCondition?.name,
              variant: res?.data?.name,
            }),
          }),
        );
        const tokenRes = await getToken(platformStoreId!);

        if (tokenRes.response?.token) {
          dispatch(
            setIFrameLink(
              `${WIDGET_EDITOR_URL}?variant=${res.data[res.data.length - 1].id}${
                activePage! ? `&type=${activePage!}` : ""
              }&platformStoreId=${platformStoreId}&token=${tokenRes.response.token}${APP_PLATFORM === Platform.Wix ? "&isNewVariant=true" : ""}&conditionId=${selectedCondition?.id}&slotId=${selectedSlot?.id}&slotNo=${selectedSlot?.slotNo}`,
            ),
          );
        }
      })
      .catch(() => error(t("Failed to add variant.")))
      .finally(() => setCreatingVariant(false));
  }, [
    activePage,
    dispatch,
    error,
    platformStoreId,
    selectedCondition?.id,
    selectedCondition?.name,
    selectedSlot?.id,
    selectedSlot?.slotNo,
    t,
    variants,
  ]);

  const navigateToEditor = useCallback(
    async (variantId: string) => {
      try {
        const tokenRes = await getToken(platformStoreId!);

        if (tokenRes.response?.token) {
          dispatch(
            setIFrameLink(
              `${WIDGET_EDITOR_URL}?variant=${variantId}${
                activePage! ? `&type=${activePage!}` : ""
              }&platformStoreId=${platformStoreId}&token=${tokenRes.response.token}&conditionId=${selectedCondition?.id}&slotId=${selectedSlot?.id}&slotNo=${selectedSlot?.slotNo}`,
            ),
          );
        }
      } catch (error) {}
    },
    [platformStoreId, dispatch, activePage, selectedCondition?.id, selectedSlot?.id, selectedSlot?.slotNo],
  );

  const handleUpdateVariantImpressionShare = useCallback(
    (id: string, oldValue: number, newValue: number) => {
      const uniqueId = `variantImpressionChange`;

      const updatedVariants = [...variants!];
      const oldValues = updatedVariants.map(a => {
        return {
          id: a.id,
          abImpressionShare: a.abImpressionShare,
        };
      });

      removeUnsavedChange(uniqueId);
      const updated = updateAbImpressionShares(id, newValue, oldValues);

      updated?.forEach(element => {
        dispatch(updateVariant({ id: element.id, abImpressionShare: element.abImpressionShare }));
      });

      pushUnsavedChanges({
        id: uniqueId,
        type: "VARIANT",
        saveAction: () =>
          dispatch(
            updateVariantAsyncByData({
              body: {
                slotId: selectedSlot?.id,
                conditionId: selectedCondition?.id,
                impressionData: updated.reduce((acc, current) => {
                  return { ...acc, [current.id]: current.abImpressionShare };
                }, {}),
              },
            }),
          )
            .unwrap()
            .then(() => {
              if (!isUnsavedBarEnabled) success(t("Variant Impression Share changed successfully."));
            })
            .catch(() => {
              error(t("Failed to change variant status."));
              oldValues.forEach(element => {
                dispatch(updateVariant({ id: element.id, abImpressionShare: element.abImpressionShare }));
              });
            }),
        discardAction: () => {
          updatedVariants
            .map(a => {
              return {
                id: a.id,
                abImpressionShare: a.abImpressionShare,
              };
            })
            .forEach(element => {
              dispatch(updateVariant({ id: element.id, abImpressionShare: element.abImpressionShare }));
            });
        },
      });
    },
    [
      variants,
      removeUnsavedChange,
      pushUnsavedChanges,
      dispatch,
      selectedSlot?.id,
      selectedCondition?.id,
      isUnsavedBarEnabled,
      success,
      t,
      error,
    ],
  );

  const handleDeleteVariantId = useCallback(
    (variantId: string) => {
      setDeletingVariant(true);
      dispatch(deleteVariantAsync(variantId))
        .unwrap()
        .then(() => {
          dispatch(
            updateBreadcrumbValue({
              breadCrumb: generateBreadcrumbFormat({ page: activePage, condition: selectedCondition?.name }),
            }),
          );
          success(t("Variant deleted successfully."));
        })
        .catch(() => {
          error(t("Error while deleting variant."));
        })
        .finally(() => {
          setIsDeleteModalOpen(false);
          setDeletingVariant(false);
        });
    },
    [dispatch, activePage, selectedCondition?.name, success, t, error],
  );

  return {
    selectedVariant,
    handleSelectedVariantUpdate,
    handleVariantStatusUpdate,
    handleVariantNameUpdate,
    handleAddWidget,
    creatingVariant,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    deletingVariant,
    handleDeleteVariant,
    createABVariant,
    navigateToEditor,
    variants,
    selectedSlot,
    handleUpdateVariantImpressionShare,
    handleDeleteVariantId,
  };
};

export default useVariants;
