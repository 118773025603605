import React from "react";

const StartFromScratch = () => {
  return (
    <svg width="148" height="56" viewBox="0 0 148 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        opacity="0.5"
        x="48.35"
        y="0.85"
        width="56.3"
        height="39.3"
        rx="3.65"
        fill="#F7F9FC"
        stroke="#9EA7B6"
        stroke-width="0.7"
        stroke-dasharray="2 1"
      />
      <rect x="43.5" y="6" width="56" height="39" rx="4.5" fill="#F7F9FC" stroke="#9EA7B6" />
      <rect x="43" y="47.5" width="45" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="43" y="52.5" width="34.7091" height="3" rx="1.5" fill="#DADEE6" />
      <circle cx="72.0999" cy="25.5999" r="10.0999" fill="#DADEE6" />
      <rect x="71.2583" y="19.7094" width="1.68332" height="11.7832" rx="0.84166" fill="#9EA7B6" />
      <rect
        x="66.209"
        y="26.4424"
        width="1.68332"
        height="11.7832"
        rx="0.84166"
        transform="rotate(-90 66.209 26.4424)"
        fill="#9EA7B6"
      />
    </svg>
  );
};

export default StartFromScratch;
