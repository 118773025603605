import { Box, Button, Divider, Flex, IconButton, Input, Spinner } from "@chakra-ui/react";
import { AlertDialogModal, Icons, Typography, useToast } from "@reconvert/react-ui-component";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectAppState, toggleHelpModal } from "../../../../app/slice/appSlice";
import { APP_PLATFORM } from "../../../../config";
import useConditions from "../../../../hooks/useConditions";
import useVariants from "../../../../hooks/useVariants";
import VariantAnalytics from "../VariantAnalytics";
import useVariantAnalytics from "../../../../hooks/useVariantAnalytics";
import { Platform } from "../../../../models";
import { updateVariant } from "../../../../app/slice/conditionSlice";
import useUnsavedBar from "../../../../hooks/useUnsavedBar";
import VariantABTest from "./VariantABTest";
import { LAUNCH_DARKLY_A_B_TESTING_SLOTS } from "../../../../utils/constant";

const VariantOverview = () => {
  const { t } = useTranslation();
  const { error } = useToast();
  const { selectedCondition } = useConditions();
  const {
    selectedVariant,
    handleVariantNameUpdate,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    deletingVariant,
    handleDeleteVariant,
    createABVariant,
    creatingVariant,
    selectedSlot,
    navigateToEditor,
  } = useVariants();
  
  const { pushUnsavedChanges, preventAction } = useUnsavedBar();
  const dispatch = useAppDispatch();
  const { variantAnalyticData, fetchAnalytics, loading } = useVariantAnalytics();
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState(selectedVariant?.name);
  // const [deletingVariant, setDeletingVariant] = useState<boolean>(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [preserveName, setPreserveName] = useState(selectedVariant?.name);

  const {
    dateRange,
    view: { isAnalyticView },
    ldFlags,
  } = useAppSelector(selectAppState);

  useEffect(() => {
    setName(selectedVariant?.name);
    setPreserveName(selectedVariant?.name);
  }, [selectedVariant]);

  useEffect(() => {
    if (!selectedSlot?.isABTestingEnabled && isAnalyticView) {
      fetchAnalytics(selectedVariant?.id ?? "", "WIDGET");
    }
  }, [dateRange, isAnalyticView, selectedVariant]);

  const toggleEdit = () => {
    if (!name) {
      setName(preserveName);
      setIsEdit(!isEdit);
      return error(t("Condition name can't be empty"));
    }

    if (isEdit && selectedCondition) {
      pushUnsavedChanges({
        id: "variantNameChange",
        type: "VARIANT",
        saveAction: () => handleVariantNameUpdate(selectedVariant.id, selectedCondition.name, name),
        discardAction: () => {
          setName(selectedVariant.name);
          dispatch(updateVariant({ id: selectedVariant.id, name: selectedVariant.name }));
        },
      });
    }

    setIsEdit(!isEdit);
  };

  const handleNameChange = (e: any) => {
    if (e.target.value) {
      setPreserveName(e.target.value);
    }

    setName(e.target.value);
  };

  const handleOnClose = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, [setIsDeleteModalOpen]);

  const height = `calc(100vh - ${APP_PLATFORM === Platform.Wix ? "60px" : "0px"})`;

  return (
    <Box flexBasis={"350px"} minWidth="350px" bg="white" boxShadow="md" height={height}>
      <>
        <Box p={4} height={height}>
          {selectedSlot?.isABTestingEnabled ? (
            <Typography
              onDoubleClick={toggleEdit}
              textAlign={"left"}
              variant="subheading1"
              fontSize={"16px"}
              color={"neutrals.800"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {selectedSlot?.name}
            </Typography>
          ) : (
            <Box>
              {isEdit ? (
                <Input
                  htmlSize={name?.length}
                  width="100%"
                  onChange={handleNameChange}
                  value={name}
                  size={"xs"}
                  onBlur={toggleEdit}
                />
              ) : (
                <Typography
                  onDoubleClick={toggleEdit}
                  textAlign={"left"}
                  variant="subheading1"
                  fontSize={"16px"}
                  color={"neutrals.800"}
                  whiteSpace={"nowrap"}
                  textOverflow={"ellipsis"}
                  overflow={"hidden"}
                >
                  {name}
                </Typography>
              )}
            </Box>
          )}
          <Box my={2}>
            {selectedSlot?.isABTestingEnabled ? (
              <Typography
                color="link.color"
                variant="body"
                as="a"
                cursor="pointer"
                className={APP_PLATFORM === Platform.Wix ? "link" : " "}
                onClick={() => {
                  const link =
                    APP_PLATFORM === Platform.Shopify
                      ? "https://help.reconvert.io/en/article/what-are-slots-and-widgets-1ban4rt/reader/"
                      : "https://help.reconvert.io/en/article/what-are-slots-and-widgets-wix-p5zh8h/reader/?bust=1716462520867";

                  dispatch(
                    toggleHelpModal({
                      link: link,
                      title: t("What are slots and widgets?").toString(),
                    }),
                  );
                }}
              >
                {t("Learn more about A/B testing")}
              </Typography>
            ) : (
              <Typography
                color="link.color"
                variant="body"
                as="a"
                cursor="pointer"
                className={APP_PLATFORM === Platform.Wix ? "link" : " "}
                onClick={() => {
                  const link =
                    APP_PLATFORM === Platform.Shopify
                      ? "https://help.reconvert.io/en/article/what-are-slots-and-widgets-1ban4rt/reader/"
                      : "https://help.reconvert.io/en/article/what-are-slots-and-widgets-wix-p5zh8h/reader/?bust=1716462520867";

                  dispatch(
                    toggleHelpModal({
                      link: link,
                      title: t("What are slots and widgets?").toString(),
                    }),
                  );
                }}
              >
                {t("Learn more about widgets")}
              </Typography>
            )}
          </Box>
          {!selectedSlot?.isABTestingEnabled && (
            <>
              <Divider />
              <Flex gap={2} mt={4} justifyContent="space-between" flexDirection="column">
                {ldFlags && ldFlags[LAUNCH_DARKLY_A_B_TESTING_SLOTS] && (
                  <Button
                    variant="secondary"
                    size={"sm"}
                    fontSize={16}
                    px={4}
                    fontWeight={500}
                    disabled={creatingVariant}
                    isLoading={creatingVariant}
                    flexGrow={1}
                    onClick={() => preventAction(() => createABVariant())}
                  >
                    {t("Create A/B test")}
                  </Button>
                )}
                <Button
                  variant="secondary"
                  size={"sm"}
                  fontSize={16}
                  px={4}
                  fontWeight={500}
                  flexGrow={1}
                  onClick={() => preventAction(() => navigateToEditor(selectedVariant?.id))}
                >
                  <Typography variant="subheading2" fontWeight="medium" lineHeight={"20px"}>
                    {t("Edit widgets")}
                  </Typography>
                </Button>
              </Flex>
            </>
          )}

          <Box
            overflowY="auto"
            overflowX="hidden"
            height={selectedSlot?.isABTestingEnabled ? "calc(100% - 110px)" : "calc(100% - 180px)"}
            pb={"20px"}
          >
            {selectedSlot?.isABTestingEnabled ? (
              <VariantABTest />
            ) : loading ? (
              <Flex justifyContent="center" mt="4">
                <Spinner />
              </Flex>
            ) : (
              <VariantAnalytics variantAnalyticData={variantAnalyticData} />
            )}
          </Box>
        </Box>
        <Box sx={{ position: "fixed", bottom: "0px", width: "100%" }} bg="white" boxShadow="md">
          <Divider />
          {selectedSlot?.isABTestingEnabled ? (
            <Flex
              pt={4}
              pl={4}
              pr={4}
              pb={"20px"}
              onClick={() => preventAction(() => createABVariant())}
              cursor={"pointer"}
            >
              <IconButton
                height={"16px"}
                aria-label={"add-variant"}
                variant="unstyled"
                disabled={creatingVariant}
                isLoading={creatingVariant}
              >
                <>
                  <Icons.PlusCircle height={"16px"} width={"16px"} color={"#2F73DA"} />
                  <Typography
                    ml={2}
                    display={"inline"}
                    alignItems={"center"}
                    variant="small"
                    color={"#2F73DA"}
                    fontWeight={"medium"}
                  >
                    {t("Add variant")}
                  </Typography>
                </>
              </IconButton>
            </Flex>
          ) : (
            <Flex
              pt={4}
              pl={4}
              pr={4}
              pb={"20px"}
              onClick={() => preventAction(() => setIsDeleteModalOpen(true))}
              cursor={"pointer"}
            >
              <IconButton height={"16px"} aria-label={"delete-variant"} variant="unstyled">
                <>
                  <Icons.TrashSimple height={"16px"} width={"16px"} color={"#EA3323"} />
                  <Typography
                    ml={2}
                    display={"inline"}
                    alignItems={"center"}
                    variant="small"
                    color={"#EA3323"}
                    fontWeight={"medium"}
                  >
                    {t("Delete widgets")}
                  </Typography>
                </>
              </IconButton>
            </Flex>
          )}
        </Box>
        <AlertDialogModal
          title={`${t("Are you sure you want to delete")} ${selectedVariant?.name}?`}
          okButtonText={t("Delete widgets")}
          size={"2xl"}
          isOpen={isDeleteModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleOnClose}
          children={null}
          okButtonOnClick={handleDeleteVariant}
          cancelButtonOnClick={handleOnClose}
          confirmLoading={deletingVariant}
        />
      </>
    </Box>
  );
};

export default React.memo(VariantOverview);
