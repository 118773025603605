import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useCallback, useContext, useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectAppState } from "../../app/slice/appSlice";
import useConditions from "../../hooks/useConditions";
import useUnsavedBar from "../../hooks/useUnsavedBar";
import { LoggerContext } from "@reconvert/react-logger";

const IFrameComponent = () => {
  const { iFrameLink, isAppBridgePresent, platformStoreId } = useAppSelector(selectAppState);
  const { handleDiscard, handleSave } = useUnsavedBar();
  const { clostIFrame } = useConditions();
  const { logger } = useContext(LoggerContext);

  const handleIFrameMessage = useCallback(
    (e: MessageEvent<any>) => {
      if (e.data.eventType === "exit" && e.data.data === true) {
        clostIFrame();

        return;
      }

      if (e.data.event === "save-form" && e.data.isInlineChange) {
        handleSave();
        return;
      }

      if (e.data.event === "discard-form" && e.data.isInlineChange) {
        handleDiscard();
        return;
      }

      if (e?.data?.page === "flow-editor") {
        const editorIframe = document.getElementById("checkout-editor-iframe");

        if (editorIframe) {
          const editorIframeWindow = (editorIframe as any)?.contentWindow;

          if (editorIframeWindow) {
            editorIframeWindow.postMessage(
              {
                ...e.data,
              },
              "*",
            );
          } else {
            console.error("Failed to get contentWindow of the iframe.");
          }
        }
      } else if (window.opener && window.top !== window) {
        window.opener.postMessage(
          {
            ...e.data,
          },
          "*",
        );
      }
    },
    [clostIFrame],
  );

  useEffect(() => {
    window.addEventListener("message", handleIFrameMessage);

    return () => {
      window.removeEventListener("message", handleIFrameMessage);
    };
  }, [handleIFrameMessage]);

  if (isAppBridgePresent) {
    logger.info(`sending message to open flow editor :: ${platformStoreId}`, iFrameLink);
    if (iFrameLink) {
      postMessage(
        {
          eventType: "open-flow-editor",
          data: { url: `${iFrameLink}&fromCondition=true` },
        },
        "*",
      );
    }

    return null;
  }

  return (
    <Modal trapFocus={false} onClose={clostIFrame} size={"full"} isOpen={!!iFrameLink} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalBody w="100%" h="100%" m={0}>
          <iframe
            title="Checkout Editor Page"
            id="checkout-editor-iframe"
            src={iFrameLink + "&fromCondition=true&isAdmin=true"}
            allowFullScreen
            style={{ width: "100%", height: "100vh" }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default IFrameComponent;
