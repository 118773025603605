import { Flex } from "@chakra-ui/react";
import { AlertDialogModal, Typography } from "@reconvert/react-ui-component";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import useSlots from "../../hooks/useSlots";
import useVariants from "../../hooks/useVariants";
import { Platform, Slot } from "../../models";
import { EmptyState } from "../EmptyState";
import SlotHeader from "../SlotHeader/SlotHeader";
import VariantCard from "../VariantCard";
import { MAX_CUSTOM_SLOT } from "@reconvert/reconvert-utils";
import { APP_PLATFORM } from "../../config";
import { getPreMadeTemplates } from "../PreMadeTemplateModal/PreMadeTemplateModal";

const SlotVariant = ({ slot, variants }: { slot: Slot; variants: any }) => {
  const { openDeleteSlotModal, setOpenDeleteSlotModal, handleSlotDelete, deletingSlot } = useSlots();
  const { creatingVariant } = useVariants();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const isTemplateSlot = APP_PLATFORM === Platform.Shopify && slot.slotNo > MAX_CUSTOM_SLOT;

  const templateSlotName = getPreMadeTemplates(false).find(a => a.slotNo === slot.slotNo)?.title;

  return (
    <>
      {!variants?.length ? (
        <EmptyState slot={slot} isLoading={creatingVariant} onSlotDelete={() => setOpenDeleteSlotModal(true)} />
      ) : (
        <Flex direction={"column"}>
          <SlotHeader slot={slot} />
          <>
            {variants[0]?.widgets?.length ? (
              <VariantCard variant={variants?.[0]} slot={slot} />
            ) : (
              <EmptyState
                onSlotDelete={() => setOpenDeleteSlotModal(true)}
                hideHeader={true}
                slot={slot}
                isLoading={creatingVariant}
              />
            )}
          </>
        </Flex>
      )}

      <AlertDialogModal
        title={
          isTemplateSlot
            ? `Are you sure you want to delete ${templateSlotName}`
            : `${t("Are you sure you want to delete slot")} ${slot.slotNo} ${slot.name}?`
        }
        okButtonText={isTemplateSlot ? `Delete ${templateSlotName}` : `${t("Delete Slot")} ${slot.slotNo}`}
        size={"2xl"}
        isOpen={openDeleteSlotModal}
        leastDestructiveRef={cancelRef}
        onClose={() => setOpenDeleteSlotModal(false)}
        okButtonOnClick={() => handleSlotDelete(slot.slotNo)}
        cancelButtonOnClick={() => setOpenDeleteSlotModal(false)}
        confirmLoading={deletingSlot}
      >
        <Typography variant="subheading2">
          {t("If you delete this slot, it will be removed from")} <strong>{t("all")}</strong>{" "}
          {t("conditions that use it.")}
        </Typography>
      </AlertDialogModal>
    </>
  );
};

export default React.memo(SlotVariant);
