import React from "react";

const BundleIcon = () => {
  return (
    <svg width="148" height="57" viewBox="0 0 148 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.998047" y="0.500275" width="43" height="33" rx="4.5" fill="#FCFDFF" stroke="#9EA7B6" />
      <rect x="0.498047" y="36.0003" width="44" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="0.498047" y="41.0003" width="34.7091" height="3" rx="1.5" fill="#DADEE6" />
      <path
        d="M48.4452 22.4677V24.0508C48.4452 24.299 48.2439 24.5003 47.9956 24.5003C47.7474 24.5003 47.5461 24.299 47.5461 24.0508V22.4677H45.9678C45.7084 22.4677 45.498 22.2573 45.498 21.9979C45.498 21.7384 45.7084 21.5281 45.9678 21.5281H47.5461V19.9498C47.5461 19.7015 47.7474 19.5003 47.9956 19.5003C48.2439 19.5003 48.4452 19.7015 48.4452 19.9498V21.5281H50.0282C50.2877 21.5281 50.498 21.7384 50.498 21.9979C50.498 22.2573 50.2877 22.4677 50.0282 22.4677H48.4452Z"
        fill="#9EA7B6"
      />
      <rect x="51.998" y="0.500275" width="43" height="33" rx="4.5" fill="#FCFDFF" stroke="#9EA7B6" />
      <rect x="51.498" y="36.0003" width="44" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="51.498" y="41.0003" width="34.7091" height="3" rx="1.5" fill="#DADEE6" />
      <path
        d="M99.4452 22.4677V24.0508C99.4452 24.299 99.2439 24.5003 98.9956 24.5003C98.7474 24.5003 98.5461 24.299 98.5461 24.0508V22.4677H96.9678C96.7084 22.4677 96.498 22.2573 96.498 21.9979C96.498 21.7384 96.7084 21.5281 96.9678 21.5281H98.5461V19.9498C98.5461 19.7015 98.7474 19.5003 98.9956 19.5003C99.2439 19.5003 99.4452 19.7015 99.4452 19.9498V21.5281H101.028C101.288 21.5281 101.498 21.7384 101.498 21.9979C101.498 22.2573 101.288 22.4677 101.028 22.4677H99.4452Z"
        fill="#9EA7B6"
      />
      <rect x="103.002" y="0.500275" width="44" height="32.2609" rx="4.5" fill="#FCFDFF" stroke="#9EA7B6" />
      <rect x="102.498" y="36.0003" width="44" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="102.498" y="41.0003" width="34.7091" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="0.5" y="47.5003" width="147" height="9" rx="4.5" fill="#FCFDFF" stroke="#9EA7B6" />
    </svg>
  );
};

export default BundleIcon;
