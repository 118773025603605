import { Box, Card, CardBody, CardProps, Flex, Stack, Switch } from "@chakra-ui/react";
import { BorderedCard, Icons, ToolTip, Typography } from "@reconvert/react-ui-component";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Condition } from "../models";
import useUnsavedBar, { PreventActionType, PreventActionTypeObject } from "../hooks/useUnsavedBar";

export interface ConditionCardProps extends CardProps {
  mode: "default" | "collapsed" | "expand";
  actions?: React.JSX.Element[];
  isConditionOn: boolean;
  toggleCondition?: (conditionId: string, oldValue: boolean, value: boolean) => void;
  title: string;
  description?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  conditionId?: string;
  condition?: Condition;
  dragHandleProps?: any;
  snapshot?: any;
}
const ConditionCard: React.FC<ConditionCardProps> = props => {
  const { t } = useTranslation();
  const { preventAction } = useUnsavedBar();

  const {
    // mode,
    actions,
    children,
    toggleCondition,
    title,
    description,
    isDisabled,
    isSelected,
    conditionId,
    condition,
    isConditionOn,
    dragHandleProps,
    snapshot,
    ...rest
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean | undefined>(undefined);
  const toggleExpanded = useCallback(() => setIsExpanded(prev => (prev === undefined ? true : !prev)), []);
  const isDefault = useMemo(() => condition?.isDefault || false, [condition]);
  const [showIcon, setShowIcon] = useState(false);
  const showExpanded = useMemo(() => {
    if (isDefault) {
      return false;
    }

    return isExpanded !== undefined ? isExpanded : isSelected || false;
  }, [isDefault, isExpanded, isSelected]);

  useEffect(() => {
    setIsExpanded(undefined);
  }, [isSelected]);

  // useEffect(() => {
  //   if (isSelected && condition?.rule) {
  //     setCardMode("expand");
  //   }
  // }, [isSelected, condition?.rule]);

  // // const [isConditionOn, setIsConditionOn] = useState<boolean>(props.isConditionOn ?? false);

  // const [cardMode, setCardMode] = useState<"collapsed" | "expand">(() => {
  //   if (mode === "collapsed") return "collapsed";

  //   if (mode === "expand") return "expand";

  //   return "collapsed";
  // });

  // const toggleMode = useCallback(() => {
  //   if (mode === "default") return;

  //   if (cardMode === "collapsed") {
  //     setCardMode("expand");
  //   } else {
  //     setCardMode("collapsed");
  //   }
  // }, [cardMode, mode]);

  const getCardBody = useCallback(() => {
    if (isDefault) {
      return (
        <>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex gap={2} alignItems="center">
              <Typography variant="subheading1-r">{title}</Typography>
              <ToolTip
                height={"100%"}
                alignItems="center"
                placement="top"
                label={t(
                  "ReConvert displays the default condition when a customer doesn't meet any specific condition, allowing for maximized conversions on your store.",
                )}
                maxWidth={"285px"}
              >
                <span>
                  <Icons.Question height={"100%"} display={"flex"} alignItems={"center"} color="neutrals.900" />
                </span>
              </ToolTip>
            </Flex>
            {/* <Icons.Eye color="neutrals.600" /> */}
          </Flex>

          <Flex height={"min-content"} mt={3} alignItems="center" justifyContent="space-between">
            <Flex>
              <Typography variant="body">{description}</Typography>
            </Flex>
          </Flex>
        </>
      );
    }

    return (
      <>
        <Flex alignItems="center" gap={2} justifyContent="space-between" width={"100%"}>
          <Flex gap={2}>
            {" "}
            <Typography variant="subheading1-r"> {title} </Typography>
            <Flex alignItems="center" gap={2} onClick={e => e.stopPropagation()}>
              <Typography variant="small" color="neutrals.900">
                {isConditionOn ? "On" : "Off"}
              </Typography>
              <Switch
                size="sm"
                color="success.800"
                isChecked={isConditionOn}
                onChange={e => {
                  preventAction(() => {
                    toggleCondition && toggleCondition(conditionId!, isConditionOn, e.target.checked);
                  }, PreventActionTypeObject.INLINE_CHANGE as PreventActionType)
                }}
              />
            </Flex>
          </Flex>
          <Flex gap={2} justifyContent="flex-end" onClick={e => e.stopPropagation()}>
            {actions &&
              actions?.length > 0 &&
              actions.map(item => {
                return item;
              })}
            {!showExpanded ? (
              <Icons.FilledArrowUp color={"#6A7381"} onClick={toggleExpanded} />
            ) : (
              <Icons.FilledArrowDown color={"#6A7381"} onClick={toggleExpanded} />
            )}
          </Flex>
        </Flex>
      </>
    );
  }, [
    isDefault,
    title,
    description,
    conditionId,
    isConditionOn,
    actions,
    showExpanded,
    toggleExpanded,
    toggleCondition,
    t,
  ]);

  return (
    <Box pl={isSelected ? 2 : 0} pr={isSelected ? 2 : 4}>
      <BorderedCard
        borderPosition="left"
        borderColor={!isSelected ? "neutrals.600" : "secondary.600"}
        borderRadius="4"
        boxShadow={isSelected ? "0px 12px 20px 0px rgba(106, 115, 129, 0.22);" : "md"}
        bg={!isConditionOn ? "neutrals.200" : undefined}
        mb={4}
      >
        <Box textAlign="left">
          <Card
            border={"none"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            p={5}
            bg={!isConditionOn ? "neutrals.200" : snapshot?.isDragging ? "grays.1" : undefined}
            color={!isConditionOn ? "disable.color" : "neutral.600"}
            size="md"
            cursor="pointer"
            _hover={{
              background: !isConditionOn ? "neutrals.200" : "grays.1",
            }}
            boxShadow={"0px 2px 8px 0px rgba(106, 115, 129, 0.12)"}
            onMouseEnter={() => {
              setShowIcon(true);
            }}
            onMouseLeave={() => {
              setShowIcon(false);
            }}
            {...rest}
          >
            <Stack w="100%">
              <CardBody padding={"16px 16px 16px 12px"}>
                <Flex gap="8px" flexDirection={isDefault ? "column" : "row"} width={"100%"}>
                  {showIcon && !isDefault && (
                    <Flex {...dragHandleProps}>
                      <Icons.DotsSixVertical cursor="grab" />
                    </Flex>
                  )}
                  {getCardBody()}
                </Flex>
                <Box mt={children ? 3 : ""}>{showExpanded && children}</Box>
              </CardBody>
            </Stack>
          </Card>
        </Box>
      </BorderedCard>
    </Box>
  );
};

export default ConditionCard;
