import { Box, Button, Divider, Flex } from "@chakra-ui/react";
import { Icons, Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectAppState,
  toggleHelpModal,
} from "../../app/slice/appSlice";
import { handleRedirectDashboard } from "../../utils/helpers";
import { APP_PLATFORM } from "../../config";
import { Platform } from "../../models";

const Header = () => {
  const { t } = useTranslation();
  const { breadcrumbValue } = useAppSelector(selectAppState);
  const dispatch = useAppDispatch();



  return (
    <>
      <Flex
        justifyContent={"space-between"}
        px={4}
        flexShrink={0}
        boxShadow={"shadow-low"}
        flexBasis={"60px"}
        zIndex={3}
      >
        <Flex justifyContent={"center"} alignItems={"center"} gap="24px">
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            gap="8px"
            onClick={handleRedirectDashboard}
            cursor={"pointer"}
          >
            <Icons.SignOut />
            <Typography variant="body">Dashboard</Typography>
          </Flex>
          <Divider orientation="vertical" />
          <Typography  variant="subheading2" fontWeight={600} color="natural.600">
           {breadcrumbValue && `Flow Builder > ${breadcrumbValue}`}
          </Typography>
        </Flex>
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Box p="0 12px 0 24px" boxSizing="border-box">
            <Button
            variant={'secondary'}
            onClick={() => {
                const link =
                  APP_PLATFORM === Platform.Shopify
                    ? "https://help.reconvert.io/en/article/how-to-use-the-flow-builder-120kpr5/reader/?bust=1706874298491"
                    : "https://help.reconvert.io/en/article/reconvert-flow-builder-for-wix-1q3qudk/reader/?bust=1716296560710";

                dispatch(
                  toggleHelpModal({
                    link: link,
                    title: t("How to use the Flow Builder").toString(),
                  }),
                );
              }}
            >
              <Typography variant="subheading2" lineHeight={"20px"} fontWeight={500}>
                {t("Help Video")}
              </Typography>
            </Button>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
